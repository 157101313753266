@import "https://fonts.googleapis.com/css?family=Chewy";
@import "https://fonts.googleapis.com/css?family=Boogaloo";
@import "https://fonts.googleapis.com/css?family=Open+Sans:200,400,500,600";
@import "https://fonts.googleapis.com/css?family=Share+Tech";
body {
  color: #fff;
  text-align: center;
  background-color: #1ba6be;
  margin: 0;
  padding: 0;
  font-family: Lucida Sans Unicode, Lucida Grande, sans-serif;
}

#warning {
  background-color: #00000080;
  padding: 10px;
  line-height: 2em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#warning img {
  vertical-align: middle;
  height: 2em;
  margin-right: 5px;
  display: inline;
}

#contact {
  text-align: left;
  background-image: url("http://paljubileet.fi/img/palm-gray-small.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 2em;
}

#outside-temperature {
  margin-top: 1em;
}

.dashboard {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

.display {
  margin: 25px 20px;
  padding: 0;
}

.display, .display div {
  border-radius: 100%;
}

.alert {
  width: 180px;
  padding: 15px;
  box-shadow: inset 0 2px 10px #00000080, -1px -1px #0000004d, 1px 1px 1px #fff6;
}

.emboss {
  background: linear-gradient(#f2f6f5 0%, #cbd5d6 100%);
  padding: 10px;
  box-shadow: inset 0 2px 2px #fff6, 0 3px 13px #000000d9;
}

.dent {
  width: 140px;
  height: 140px;
  vertical-align: middle;
  transform-style: preserve-3d;
  background: linear-gradient(to top, #f2f6f5 0%, #cbd5d6 100%);
  padding: 10px;
}

.content {
  color: #555;
  text-shadow: 1px 1px 1px #fff;
  text-align: center;
  margin: auto;
  font-family: Share Tech;
  font-size: 60px;
  font-weight: 500;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.content span {
  font-size: 30px;
  font-weight: 300;
}

.content strong {
  font-size: 22px;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 40px;
}

.content div.label {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
}

.chart {
  width: 650px;
  margin: 20px;
}

#gradient-defs-svg {
  z-index: -1000;
  position: absolute;
  left: 0;
  right: 0;
}

.ct-series-a .ct-line, .ct-series-b .ct-line {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut, .ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut {
  stroke: url("#line-gradient") !important;
}

.ct-series-a .ct-slice-pie, .ct-series-a .ct-area, .ct-series-b .ct-slice-pie, .ct-series-b .ct-area {
  fill: url("#line-gradient") !important;
}

.ct-label {
  fill: #fffc;
  color: #fffc;
  font-size: .75rem;
  line-height: 1;
}

.ct-chart-line .ct-label, .ct-chart-bar .ct-label {
  display: flex;
}

.ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  text-align: left;
  text-anchor: start;
  justify-content: flex-start;
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  text-align: left;
  text-anchor: start;
  justify-content: flex-start;
}

.ct-label.ct-vertical.ct-start {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  text-align: right;
  text-anchor: end;
  justify-content: flex-end;
}

.ct-label.ct-vertical.ct-end {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  text-align: left;
  text-anchor: start;
  justify-content: flex-start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  text-align: center;
  text-anchor: start;
  justify-content: center;
  align-items: flex-end;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  text-align: center;
  text-anchor: start;
  justify-content: center;
  align-items: flex-start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  text-align: left;
  text-anchor: start;
  justify-content: flex-start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  text-align: left;
  text-anchor: start;
  justify-content: flex-start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  align-items: center;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  text-align: right;
  text-anchor: end;
  justify-content: flex-end;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  text-align: left;
  text-anchor: end;
  justify-content: flex-start;
}

.ct-grid {
  stroke: #fff3;
  stroke-width: 1px;
  stroke-dasharray: 2;
}

.ct-point {
  stroke-width: 8px;
  stroke-linecap: round;
}

.ct-line {
  fill: none;
  stroke-width: 4px;
}

.ct-area {
  stroke: none;
  fill-opacity: .1;
}

.ct-bar {
  fill: none;
  stroke-width: 10px;
}

.ct-slice-donut {
  fill: none;
  stroke-width: 60px;
}

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
  stroke: #fe9804;
}

.ct-series-a .ct-slice-pie, .ct-series-a .ct-area {
  fill: #fe9804;
}

.ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut {
  stroke: #f05b4f;
}

.ct-series-b .ct-slice-pie, .ct-series-b .ct-area {
  fill: #f05b4f;
}

.ct-series-c .ct-point, .ct-series-c .ct-line, .ct-series-c .ct-bar, .ct-series-c .ct-slice-donut {
  stroke: #f4c63d;
}

.ct-series-c .ct-slice-pie, .ct-series-c .ct-area {
  fill: #f4c63d;
}

.ct-series-d .ct-point, .ct-series-d .ct-line, .ct-series-d .ct-bar, .ct-series-d .ct-slice-donut {
  stroke: #d17905;
}

.ct-series-d .ct-slice-pie, .ct-series-d .ct-area {
  fill: #d17905;
}

.ct-series-e .ct-point, .ct-series-e .ct-line, .ct-series-e .ct-bar, .ct-series-e .ct-slice-donut {
  stroke: #453d3f;
}

.ct-series-e .ct-slice-pie, .ct-series-e .ct-area {
  fill: #453d3f;
}

.ct-series-f .ct-point, .ct-series-f .ct-line, .ct-series-f .ct-bar, .ct-series-f .ct-slice-donut {
  stroke: #59922b;
}

.ct-series-f .ct-slice-pie, .ct-series-f .ct-area {
  fill: #59922b;
}

.ct-series-g .ct-point, .ct-series-g .ct-line, .ct-series-g .ct-bar, .ct-series-g .ct-slice-donut {
  stroke: #0544d3;
}

.ct-series-g .ct-slice-pie, .ct-series-g .ct-area {
  fill: #0544d3;
}

.ct-series-h .ct-point, .ct-series-h .ct-line, .ct-series-h .ct-bar, .ct-series-h .ct-slice-donut {
  stroke: #6b0392;
}

.ct-series-h .ct-slice-pie, .ct-series-h .ct-area {
  fill: #6b0392;
}

.ct-series-i .ct-point, .ct-series-i .ct-line, .ct-series-i .ct-bar, .ct-series-i .ct-slice-donut {
  stroke: #f05b4f;
}

.ct-series-i .ct-slice-pie, .ct-series-i .ct-area {
  fill: #f05b4f;
}

.ct-series-j .ct-point, .ct-series-j .ct-line, .ct-series-j .ct-bar, .ct-series-j .ct-slice-donut {
  stroke: #dda458;
}

.ct-series-j .ct-slice-pie, .ct-series-j .ct-area {
  fill: #dda458;
}

.ct-series-k .ct-point, .ct-series-k .ct-line, .ct-series-k .ct-bar, .ct-series-k .ct-slice-donut {
  stroke: #eacf7d;
}

.ct-series-k .ct-slice-pie, .ct-series-k .ct-area {
  fill: #eacf7d;
}

.ct-series-l .ct-point, .ct-series-l .ct-line, .ct-series-l .ct-bar, .ct-series-l .ct-slice-donut {
  stroke: #86797d;
}

.ct-series-l .ct-slice-pie, .ct-series-l .ct-area {
  fill: #86797d;
}

.ct-series-m .ct-point, .ct-series-m .ct-line, .ct-series-m .ct-bar, .ct-series-m .ct-slice-donut {
  stroke: #b2c326;
}

.ct-series-m .ct-slice-pie, .ct-series-m .ct-area {
  fill: #b2c326;
}

.ct-series-n .ct-point, .ct-series-n .ct-line, .ct-series-n .ct-bar, .ct-series-n .ct-slice-donut {
  stroke: #6188e2;
}

.ct-series-n .ct-slice-pie, .ct-series-n .ct-area {
  fill: #6188e2;
}

.ct-series-o .ct-point, .ct-series-o .ct-line, .ct-series-o .ct-bar, .ct-series-o .ct-slice-donut {
  stroke: #a748ca;
}

.ct-series-o .ct-slice-pie, .ct-series-o .ct-area {
  fill: #a748ca;
}

.ct-square {
  width: 100%;
  display: block;
  position: relative;
}

.ct-square:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 100%;
  display: block;
}

.ct-square:after {
  content: "";
  clear: both;
  display: table;
}

.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-second {
  width: 100%;
  display: block;
  position: relative;
}

.ct-minor-second:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 93.75%;
  display: block;
}

.ct-minor-second:after {
  content: "";
  clear: both;
  display: table;
}

.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-second {
  width: 100%;
  display: block;
  position: relative;
}

.ct-major-second:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 88.8889%;
  display: block;
}

.ct-major-second:after {
  content: "";
  clear: both;
  display: table;
}

.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-third {
  width: 100%;
  display: block;
  position: relative;
}

.ct-minor-third:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 83.3333%;
  display: block;
}

.ct-minor-third:after {
  content: "";
  clear: both;
  display: table;
}

.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-third {
  width: 100%;
  display: block;
  position: relative;
}

.ct-major-third:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 80%;
  display: block;
}

.ct-major-third:after {
  content: "";
  clear: both;
  display: table;
}

.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fourth {
  width: 100%;
  display: block;
  position: relative;
}

.ct-perfect-fourth:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 75%;
  display: block;
}

.ct-perfect-fourth:after {
  content: "";
  clear: both;
  display: table;
}

.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fifth {
  width: 100%;
  display: block;
  position: relative;
}

.ct-perfect-fifth:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 66.6667%;
  display: block;
}

.ct-perfect-fifth:after {
  content: "";
  clear: both;
  display: table;
}

.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-sixth {
  width: 100%;
  display: block;
  position: relative;
}

.ct-minor-sixth:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 62.5%;
  display: block;
}

.ct-minor-sixth:after {
  content: "";
  clear: both;
  display: table;
}

.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-golden-section {
  width: 100%;
  display: block;
  position: relative;
}

.ct-golden-section:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 61.8047%;
  display: block;
}

.ct-golden-section:after {
  content: "";
  clear: both;
  display: table;
}

.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-sixth {
  width: 100%;
  display: block;
  position: relative;
}

.ct-major-sixth:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 60%;
  display: block;
}

.ct-major-sixth:after {
  content: "";
  clear: both;
  display: table;
}

.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-seventh {
  width: 100%;
  display: block;
  position: relative;
}

.ct-minor-seventh:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 56.25%;
  display: block;
}

.ct-minor-seventh:after {
  content: "";
  clear: both;
  display: table;
}

.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-seventh {
  width: 100%;
  display: block;
  position: relative;
}

.ct-major-seventh:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 53.3333%;
  display: block;
}

.ct-major-seventh:after {
  content: "";
  clear: both;
  display: table;
}

.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-octave {
  width: 100%;
  display: block;
  position: relative;
}

.ct-octave:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 50%;
  display: block;
}

.ct-octave:after {
  content: "";
  clear: both;
  display: table;
}

.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-tenth {
  width: 100%;
  display: block;
  position: relative;
}

.ct-major-tenth:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 40%;
  display: block;
}

.ct-major-tenth:after {
  content: "";
  clear: both;
  display: table;
}

.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-eleventh {
  width: 100%;
  display: block;
  position: relative;
}

.ct-major-eleventh:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 37.5%;
  display: block;
}

.ct-major-eleventh:after {
  content: "";
  clear: both;
  display: table;
}

.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-twelfth {
  width: 100%;
  display: block;
  position: relative;
}

.ct-major-twelfth:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 33.3333%;
  display: block;
}

.ct-major-twelfth:after {
  content: "";
  clear: both;
  display: table;
}

.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-double-octave {
  width: 100%;
  display: block;
  position: relative;
}

.ct-double-octave:before {
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 25%;
  display: block;
}

.ct-double-octave:after {
  content: "";
  clear: both;
  display: table;
}

.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 900px) {
  .dashboard {
    width: 100%;
    display: block;
  }

  .displays {
    margin: auto;
    padding: 0;
    display: block;
  }

  .display {
    align-items: center;
    margin: 20px;
    display: inline-flex;
  }

  .chart {
    margin: 0;
    padding: 0;
    width: 100% !important;
  }
}

@media (max-width: 375px) {
  .chart {
    width: 95%;
    margin: 0;
    padding: 0;
  }
}

/*# sourceMappingURL=index.c24daaae.css.map */
